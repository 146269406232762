var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showModal)?_c('BaseModal',[_c('RegisterOrganizationModal',{attrs:{"organization":_vm.organization,"headline":_vm.modalHeadline,"type":_vm.modalType},on:{"close":function($event){return _vm.closeModalAndRefresh()}}})],1):_vm._e(),_c('v-form',[_c('BaseTableFiltered',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.organizations,"search":_vm.searchOrganization,"filter":_vm.filter},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"}},[_vm._v("Adressebok - Firma")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk blant organisasjoner","single-line":""},model:{value:(_vm.searchOrganization),callback:function ($$v) {_vm.searchOrganization=$$v},expression:"searchOrganization"}}),_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Legg til organisasjon","icon":"mdi-plus"},on:{"handleClick":_vm.addOrganization}})]},proxy:true},{key:"item.isActive",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Aktiv" : "Inaktiv")+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.displayOrganization(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,true)},[_c('span',[_vm._v("Vis "+_vm._s(item.name))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.editOrganization(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Rediger "+_vm._s(item.place))])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }