


















































































































































































































import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { setShowLoadingSpinner } from "@/shared/helpers/store.helpers";
import {
  validateNotEmpty,
  validateIsNorwegianPhoneNumber,
  validateIsOrganizationNumber,
  validateIsEmail,
  validateIsNorwegianZipCode,
} from "@/shared/helpers/validationHelpers";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { ApiGetCustomerDto } from "@/api/generated/Api";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import Vue from "vue/types/umd";
import { api } from "@/api/api";
import { formatRelative } from "@/shared/helpers/dateHelpers";

export default defineComponent({
  name: "RegisterOrganizationModal",
  components: { BaseModalForm },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore<StoreState>();
    const organizationValues = useAutoDeepCloneProp<ApiGetCustomerDto>(props, "organization");

    if (props.type === ModalType.Add) {
      organizationValues.value.isActive = true;
    }

    const countryItems = [{ label: "Norge", value: "NO" }];

    const validForm = ref(false);
    const sameAsInvoiceAddress = ref(true);

    const setInvoiceAddressSameAsAddress = async () => {
      organizationValues.value.invoiceAddress1 = organizationValues.value.postAddress1;
      organizationValues.value.invoiceZip = organizationValues.value.postZip;
      organizationValues.value.invoiceCity = organizationValues.value.postCity;
      organizationValues.value.invoiceCountry = organizationValues.value.postCountry;
    };

    const handleSubmit = async () => {
      const isValid = (context.refs.form as Vue & { validate: () => boolean }).validate();
      if (!isValid) {
        return;
      }
      setShowLoadingSpinner(store, true);
      try {
        if (sameAsInvoiceAddress.value) {
          setInvoiceAddressSameAsAddress();
        }

        if (props.type === ModalType.Add) {
          await api.customer.createCustomerOrganizationAsync(organizationValues.value);
          context.emit("close");
        } else if (props.type === ModalType.Edit) {
          await api.customer.updateOrganizationAsync(organizationValues.value.id, organizationValues.value);
          context.emit("close");
        }
      } catch (err) {
        openNotification(store, NotificationItemType.Error, "En feil oppsto ved lagring.");
      } finally {
        setShowLoadingSpinner(store, false);
      }
    };
    onMounted(() => {
      if (props.type !== ModalType.Add && organizationValues.value.invoiceAddress1) {
        sameAsInvoiceAddress.value = false;
      }
    });
    return {
      countryItems,
      validForm,
      sameAsInvoiceAddress,
      readonly: computed(() => props.type === ModalType.Display),
      organizationValues,
      handleSubmit,
      validateNotEmpty,
      validateIsNorwegianPhoneNumber,
      validateIsOrganizationNumber,
      validateIsEmail,
      validateIsNorwegianZipCode,
      formatRelative,
      ModalType,
    };
  },
});
