


























































import { api } from "@/api/api";
import { ApiGetCustomerDto } from "@/api/generated/Api";
import RegisterOrganizationModal from "@/components/contacts/RegisterOrganizationModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { VSwitch, VTextField } from "vuetify/lib";

export default defineComponent({
  name: "ContactsOrganisationPage",
  components: {
    BaseTooltipIconButton,
    BaseTableFiltered,
    BaseModal,
    RegisterOrganizationModal,
  },
  setup() {
    const initialOrganization: ApiGetCustomerDto = {
      id: 0,
      isActive: false,
      insertDate: "",
      insertedBy: 0,
      updateDate: "",
      updatedBy: 0,
      lastVerifiedContactInfoDate: "",
      invoiceAddress1: "",
      invoiceZip: "",
      invoiceCity: "",
      invoiceCountry: "",
      customer: {
        customerId: 0,
        name: "",
        orgNr: "",
        homePage: "",
      },
      adUserId: "",
    };

    const searchOrganization = ref("");
    const showModal = ref(false);
    const modalHeadline = ref("");
    const modalType = ref("");
    const organization = ref(initialOrganization);

    const organizations = ref<ApiGetCustomerDto[]>([]);

    const filter = computed(() => [
      {
        component: VTextField,
        value: "customer",
        default: "",
        attrs: {
          label: "Firmanavn",
          dense: true,
          hideDetails: true,
          clearable: true,
        },
        apply: (value: any, model: any) => {
          if (!model || !value) {
            return;
          }
          const customerName = value.name;
          return customerName.toLowerCase().includes(model.toLowerCase());
        },
      },
      // TODO ==> Cannot have two identical value: "customer". Need another solution for organization number search.
      // {
      //   component: VTextField,
      //   value: "customer",
      //   default: "",
      //   attrs: {
      //     label: "Org.nummer",
      //     dense: true,
      //     hideDetails: true,
      //     clearable: true,
      //   },
      //   apply: (value: any, model: any) => {
      //     debounceHelper.debounce("inputOrgNumberSearch", 500);
      //     if (!model || !value) {
      //       return;
      //     }
      //     const orgNumber = value.orgNumber;
      //     return orgNumber.includes(model);
      //   },
      // },
      {
        component: VTextField,
        value: "email",
        default: "",
        attrs: {
          label: "E-post",
          dense: true,
          hideDetails: true,
          clearable: true,
        },
        apply: (value: string, model: string) => {
          if (!model || !value) {
            return;
          }
          return value.toLowerCase().includes(model.toLowerCase());
        },
      },
      {
        component: VSwitch,
        value: "isActive",
        default: true,
        staticClass: "mx-3",
        attrs: {
          inset: true,
          label: "Vis inaktive",
        },
        apply: (value: any, model: any) => model || value,
      },
    ]);

    const closeModalAndRefresh = () => {
      fetchOrganizations();
      showModal.value = false;
    };
    const addOrganization = () => {
      organization.value = initialOrganization;
      showModal.value = true;
      modalHeadline.value = "Legg til organisasjon";
      modalType.value = ModalType.Add;
    };
    const displayOrganization = (item: any) => {
      organization.value = item;
      showModal.value = true;
      modalHeadline.value = item.customer.name;
      modalType.value = ModalType.Display;
    };
    const editOrganization = (item: any) => {
      organization.value = item;
      showModal.value = true;
      modalHeadline.value = `Rediger ${item.customer.name}`;
      modalType.value = ModalType.Edit;
    };
    const fetchOrganizations = async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const response = await api.customer.getCustomerOrganizationsAsync();
        organizations.value = response.data;
      });
    };

    onMounted(() => {
      fetchOrganizations();
    });

    return {
      headers,
      searchOrganization,
      showModal,
      modalHeadline,
      modalType,
      organizations,
      addOrganization,
      displayOrganization,
      editOrganization,
      organization,
      closeModalAndRefresh,
      filter,
    };
  },
});

const headers = [
  {
    text: "Navn",
    value: "customer.name",
  },
  {
    text: "Organisasjonsnummer",
    value: "customer.orgNumber",
  },
  {
    text: "E-post",
    value: "email",
  },
  {
    text: "Mobilnummer",
    value: "mobileNumber",
  },
  {
    text: "Status",
    value: "isActive",
  },
  {
    text: "Handlinger",
    value: "actions",
    sortable: false,
  },
];
